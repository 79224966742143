/* Converts a USD value to a number (i.e. $5.73 -> 5.73)
 * - value -> The USD value to convert
 */
export const USDToNumber = (value: string): number => {
  value = value.replace("$", "");
  value = value.replace(",", "");
  return Number.parseFloat(value);
};

/* Converts a number to USD string format (i.e. 5.73 -> $5.73)
 * - value -> The string/number to convert to USD format
 */
export const NumberToUSD = (value: string | number): string => {
  const NumCoercedValue = Number.parseFloat(value.toString());
  const Formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return Formatter.format(NumCoercedValue);
};
