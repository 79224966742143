import React from "react";
import { Link } from "gatsby";
import { Grid, Breadcrumbs, Typography, makeStyles } from "@material-ui/core";

const UseStyles = makeStyles({
  breadcrumbs: {
    background: "rgba(153,153,153,0.1)",
    paddingLeft: "1.5rem",
    paddingTop: "0.35rem",
    paddingBottom: "0.35rem",
  },

  link: {
    color: "#AFBD21",
  },

  lastLink: {
    fontWeight: "bold",
    color: "black",
  },
});

type BreadcrumbProps = {
  pathObj: Record<string, unknown>;
};

/*
 * pathObj -> JSON object (keys are link text; values are hrefs)
 */
const Breadcrumb = (props: BreadcrumbProps): JSX.Element => {
  const Classes = UseStyles();

  return (
    <Grid item xs={12}>
      <Breadcrumbs className={Classes.breadcrumbs}>
        {Object.entries(props.pathObj).map(([key, value], index, array) => {
          if (index !== array.length - 1) {
            return (
              <Link key={index} to={value as string} className={Classes.link}>
                {key}
              </Link>
            );
          } else {
            return (
              <Typography
                key={index}
                color="textPrimary"
                className={Classes.lastLink}
              >
                {key}
              </Typography>
            );
          }
        })}
      </Breadcrumbs>
    </Grid>
  );
};

export default Breadcrumb;
