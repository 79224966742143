import React from "react";
import Layout from "../components/layout";
import Breadcrumb from "../components/breadcrumb";
import GlobalConstants from "../helpers/constants";
import { Grid, Typography } from "@material-ui/core";
import { GetComponentByName } from "../helpers/query-data";
import { graphql } from "gatsby";
import { GetSimplePathObj } from "../helpers/data-formatting";
import { GQLQuery, GQLRichTextContent } from "../types";

type TermsProps = {
  data: {
    crystallize: GQLQuery;
  };
};

const Terms = (props: TermsProps): JSX.Element => {
  const QueryData = props.data.crystallize.catalogue;
  const ContentCompContent = GetComponentByName(
    QueryData.components,
    GlobalConstants.Content
  ).content as GQLRichTextContent;
  const PathObj = GetSimplePathObj(GlobalConstants.TermsAndConditions);

  return (
    <Layout>
      <Breadcrumb pathObj={PathObj} />
      <Grid item xs={12}>
        <Typography variant="h6">{QueryData.name}</Typography>
        {ContentCompContent.html.map((node, index) => (
          <div
            key={index}
            className="MuiTypography-root MuiTypography-body2"
            dangerouslySetInnerHTML={{ __html: node }}
          />
        ))}
      </Grid>
    </Layout>
  );
};

export const Query = graphql`
  query {
    crystallize {
      catalogue(path: "/articles/terms-and-conditions") {
        ...TAC_item
      }
    }
  }

  fragment TAC_item on CRYSTALLIZE_Item {
    name
    components {
      ...TAC_component
    }
  }

  fragment TAC_component on CRYSTALLIZE_Component {
    name
    content {
      ...TAC_singleLine
      ...TAC_richText
    }
  }

  fragment TAC_singleLine on CRYSTALLIZE_SingleLineContent {
    text
  }

  fragment TAC_richText on CRYSTALLIZE_RichTextContent {
    html
  }
`;

export default Terms;
